@use "../Helpers/Palette.scss" as Palette;
@use "../Helpers/Mixin.scss" as Mixin;

.Newsletter {
  height: 50vh;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  h2 {
    font-size: 3em;
    font-weight: bold;
  }
  p {
    text-align: center;
    color: Palette.$featurePrimary;
  }

  .form {
    background-color: Palette.$secondary;
    width: 100%;
    height: 45%;
    margin: 10vh auto auto auto;
    border: 5px solid Palette.$primary;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    input {
      height: 100%;
      width: 72.5%;
      border: none;
      border-radius: 15px;
      margin-left: 2.5%;
      font-size: 1.2em;
      font-weight: bolder;
      text-transform: uppercase;
      background-color: white;
      &:focus {
        border: none;
        outline: none;
      }
    }
    button {
      width: 20%;
      border: 1px solid Palette.$primary;
      border-radius: 5px;
      padding: 10px 0px;
      margin: auto 2.5%;
      background-color: Palette.$primary;
      color: Palette.$secondary;
      font-weight: bold;
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        transition: all 0.2s;
        opacity: 0.5;
      }
    }
  }
}
.imageReverse {
  transform: translateY(-50px) rotateX(150deg);
}

@include Mixin.Mobile {
  .Newsletter {
    width: 80%;
    margin: auto;
    text-align: center;
    margin-bottom: 10vh;

    .form {
      height: 35%;
      input{
        width: 62.5%;
        font-weight: initial;
        font-size: 1em;
      }

      button {
        width: 25%;
        font-size: 0.8em;
        font-weight: initial;
      }
    }
  }
  .imageReverse {
    transform: translateY(-10px) rotateX(150deg);
  }
}
