@use "../Helpers/Palette.scss" as Palette;
@use "../Helpers/Mixin.scss" as Mixin;

.Mission {
  min-height: max-content;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 50px;
  margin: 10vh 0;
  .primary {
    width: 40%;
    height: 60%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    row-gap: 10px;

    h4 {
      font-weight: bold;
      font-size: 1.2em;
    }

    h2 {
      font-size: 2.7em;
    }

    p {
      font-size: 1.2em;
    }

    button {
      border: 1px solid Palette.$primary;
      border-radius: 5px;
      padding: 10px 40px;
      background-color: Palette.$primary;
      color: Palette.$secondary;
      font-weight: bold;
      text-transform: uppercase;
      cursor: pointer;
      &:hover {
        border: 1px solid Palette.$primary;
        background-color: Palette.$secondary;
        color: Palette.$primary;
        transition: all 0.2s;
      }
    }
  }

  .secondary {
    width: 40%;
    height: 60%;
    display: flex;
    justify-content: 'center';
    align-items: 'flex-start';
    flex-direction: column;
    p {
      font-size: 1.3em;
    }
  }
}

@include Mixin.Mobile {
  .Mission {
    flex-direction: column;
    row-gap: 50px;
    justify-content: center;
    align-items: center;

    .primary {
      width: 80%;
      justify-content: center;
      align-items: center;
    }

    .secondary {
      width: 80%;
      justify-content: center;
      align-items: center;
    }
  }
}
