@use "../Helpers/Palette.scss" as Palette;
@use "../Helpers/Mixin.scss" as Mixin;

.Backers {
  min-height: max-content;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 100px;
  background-color: Palette.$secondary;
  color: Palette.$primary;
  padding: 0 0 10vh 0 ;
  margin-bottom: 5vh;
  .primary {
    width: 80%;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 10px;

    .partenaire {
      width: 50%;
      align-self: start;
      margin: 2.5vh auto;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      row-gap: calc(10% / 3);
      text-transform: capitalize;

     
      img{
      max-width: calc(30%);
      max-height:80px;
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
      }
    }
    .allbtn {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      width: 100%;
      column-gap: 30px;
      margin-top: 4vh;
    }
    h4 {
      font-weight: bold;
      font-size: 1.2em;
    }

    h2 {
      font-size: 2.7em;
      width: 80%;
      color: initial;
      text-align: center;
    }

    p {
      font-size: 1.2em;
    }

    button {
      border: 1px solid Palette.$secondary;
      border-radius: 5px;
      padding: 10px 40px;
      background-color: Palette.$secondary;
      color: Palette.$primary;
      font-weight: bold;
      text-transform: uppercase;
      cursor: pointer;
      &:hover {
        border: 1px solid Palette.$secondary;
        background-color: Palette.$primary;
        color: Palette.$secondary;
        transition: all 0.2s;
      }
    }

    button:last-child {
      background-color: Palette.$primary;
      color: Palette.$secondary;
      border: 1px solid Palette.$secondary;
      &:hover {
        border: 1px solid Palette.$primary;
        background-color: Palette.$secondary;
        color: Palette.$primary;
        transition: all 0.2s;
      }
    }
  }

  // .secondary {
  //   width: 40%;
  //   height: 60%;
  //   p {
  //     font-size: 1.3em;
  //   }
  // }
}


@include Mixin.Mobile {
  .partenaire{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}