@use "../Helpers/Palette.scss" as Palette;
@use "../Helpers/Mixin.scss" as Mixin;

.Feature {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .partenaire {
    height: 20vh;
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5vh;
    flex-wrap: wrap;
  }

  .primaryFeature {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 80%;

    &__image {
      position: relative;
      img {
        z-index: 1;
        width: 100%;
      }
      .sousImage {
        position: absolute;
        top: 0;
        z-index: -1;
        img {
          height: 30%;
        }
      }
    }

    &__text {
      padding: 20px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      & > * {
        width: 70%;
      }
      h2 {
        font-weight: bold;
        color: Palette.$primary;
        margin-bottom: 10px;
        font-size: 2.5em;
      }

      div.params {
        color: Palette.$featurePrimary;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        column-gap: 10px;
        p{
          font-size: 1.2em;
        }
      }

      & > p:first-child {
        align-self: flex-end;
      }

      a {
        color: Palette.$header;
        text-decoration: none;
      }
    }
  }

  .secondaryFeature {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 25vh 0 15vh 0;

    &__img {
      width: 40%;
      img {
        height: 100%;
        max-width: 100%;
      }
    }

    &__text {
      padding: 20px 0px;
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      & > * {
        width: 70%;
      }

      div.params {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        column-gap: 10px;
        font-size: 1.2em;
      }

      .list{
        margin-top: 10px;
      }
      h2 {
        font-weight: bold;
        color: Palette.$primary;
        text-transform: capitalize;
        font-size: 2.5em;
      }

      p {
        color: Palette.$featurePrimary;
      }

      a {
        color: Palette.$header;
        text-decoration: none;
      }
    }
  }
}

@include Mixin.Mobile {
  .primaryFeature {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__text {
      h2 {
        width: 100% !important;
        margin-bottom: 20px;
      }

      p {
        width: 100% !important;
      }

      a {
        width: 100%;
      }
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  .secondaryFeature {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    margin-top: 10vh;
    min-height: max-content;
    &__text {
      & > * {
        width: 100% !important;
      }
      h2 {
        width: 100% !important;
        margin-bottom: 20px;
      }

      p {
        width: 100% !important;
      }

      a {
        width: 100%;
      }
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    &__img {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
}
